<template>
  <v-container class="pa-0" id="font" fluid fill-height style="background-color: #f0f5fa">
    <!-- <button @click="$refs.myPdfComponent.print()">print</button> -->
    <v-card v-if="acceptannexueScreen" id="pdfpageheight" class="mx-auto overflow-y-auto" :width="!$vuetify.breakpoint.mobile ? 'calc(100vw - 40%)' : '100vw'">
      <v-progress-linear v-if="overlaypdf" class="mt-3 pt-3" color="#1976D2" indeterminate rounded height="6"></v-progress-linear>
      <pdf v-for="i in numPages" :key="i" :page="i" @page-loaded="$event == numPages ? (overlaypdf = false) : (overlaypdf = false)" :src="tncurl"> </pdf>
    </v-card>
    <!-- <v-checkbox v-else hide-details class="ma-0" color="secondary" @click="acceptannexueScreen = true"></v-checkbox> -->
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
// var loadingTask = pdf.createLoadingTask("Disclosure_and_Tariff.pdf");

// const ifscErrorDesc=(value,vm) =>vm.ifscErrorDesc
export default {
  components: {pdf},
  data() {
    return {
      src: "",
      numPages: undefined,

      currentPage: 0,
      pageCount: 0,
      overlaypdf: true,
      isScrolledtoBottom: true,
      offsetTop: 0,

      forstudent: false,
      heisStudent: false,

      // temproryFix zero charges
      zeroAccountCharge: true,

      acceptannexueScreen: false,
      tncurl: "https://zebu.mynt.in/static/Downloads/download_docs/kyc/Disclosure_and_Tariff.pdf",
    };
  },
  mounted() {
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });

    setTimeout(() => {
      this.acceptannexueScreen = true;
    }, 100);
  },
  methods: {
    onScroll(e) {
      const {offsetHeight} = e.target.body;
      if (offsetHeight - 2000 >= window.scrollY) {
        this.isScrolledtoBottom = true;
      } else if (window.scrollY > 300) {
        this.isScrolledtoBottom = false;
      }
    },
  },
  created() {
    this.src = pdf.createLoadingTask(this.tncurl);
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scope>
.v-input__append-inner > .v-input__icon > .v-icon {
  font-size: 14px !important;
  color: #377dff;
}

body {
  touch-action: auto !important;
}
</style>
