<template>
  <div>
    <!-- <v-container class="overflow-auto py-16" fill-height width="100%"> -->
    <!-- <v-card width="100%" class="text-center elevation-0 py-16 my-16">
      <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
    </v-card> -->
    <!-- {{ acctoken }} {{ loaders }} -->
    <!-- <v-btn class="mb-4 mx-4 text-none" :loading="loaders" @click="startOnboarding('workflow_jDFDeDc')"> Start onb </v-btn> -->
    <!-- <v-btn class="mb-4 mx-4 text-none" :loading="loaders" @click="startOnboarding('zebu_wet_signature')"> Start wet signature </v-btn> -->
    <!-- <v-btn class="mb-4 mx-4 text-none" :loading="loaders" @click="startOnboarding('zebu_flow')"> Start selfie </v-btn> -->
    <!-- </v-container> -->
  </div>
</template>

<script>
import api from "@/api";
import axios from "axios";
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      snackcolor: "#2A2A2A",
      snacktxt: null,
      snackbar: false,

      loaders: false,
      acctoken: null,

      uid: null,
    };
  },
  methods: {
    startOnboarding(type) {
      const hyperKycConfig = new window.HyperKycConfig(this.acctoken.token, type, this.uid);
      window.HyperKYCModule.launch(hyperKycConfig, this.setHandler);
    },

    setHandler(HyperKycResult) {
      switch (HyperKycResult.status) {
        // ----Incomplete workflow-----

        case "user_cancelled":
          this.setResponesHv(HyperKycResult, 2, "Cancelled by yourself");
          break;
        case "error":
          this.setResponesHv(HyperKycResult, 0, "server error");
          break;

        // ----Complete workflow-----

        case "auto_approved":
          this.setResponesHv(HyperKycResult, 1, "Successfully completed");
          break;
        case "auto_declined":
          this.setResponesHv(HyperKycResult, 0, "Data missmatch");
          break;
        case "needs_review":
          this.setResponesHv(HyperKycResult, 1, "Manually reviewed");
          break;
      }
    },
    setResponesHv(raw, clr, msg) {
      console.log(raw, clr, msg);
      if (raw) {
        this.$emit("setHVstat", raw, clr, msg, this.uid);
      } else if (clr == 1) {
        // && clr == 3 this.$emit("setHVstat", raw, clr, msg, this.uid);

        let data = JSON.stringify({
          transactionId: this.uid,
          sendDebugInfo: "no",
          sendReviewDetails: "yes",
        });

        let config = {
          method: "post",
          url: `${api.hyv}/v1/output`,
          headers: {
            appId: this.acctoken.appId,
            appKey: this.acctoken.appKey,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((res) => {
            if (res.data) {
              console.log(res.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async getLoginSess() {
      let config = {
        method: "post",
        url: `${api.api_url}/HypervergeAuth`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      let axs = this;
      await axios
        .request(config)
        .then((res) => {
          if (res.data && res.data.appId && res.data.token) {
            axs.acctoken = res.data;
            var url = new URL(window.location.href);
            const mode = url.searchParams.get("mode");
            if (mode) {
              axs.mode = mode;
            }
            axs.uid = atob(localStorage.getItem("hash")) + `_${axs.mode == "zebu_flow" ? "IPV1" : "Sig1"}`;
            setTimeout(() => {
              axs.startOnboarding(axs.mode);
            }, 100);
          } else {
            axs.acctoken = null;
          }
          axs.loaders = false;
        })
        .catch((error) => {
          console.log(error);
          axs.acctoken = null;
          axs.loaders = false;
        });
    },
  },

  async mounted() {
    const script = document.createElement("script");
    // script.src = "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@6.1.1/src/sdk.min.js";
    script.src = "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.9.1/src/sdk.min.js";
    script.async = true;
    await document.head.appendChild(script);
    setTimeout(() => {
      this.getLoginSess();
    }, 0);
  },
};
</script>

<!--
<style>
.hv-back-close-flex-div {
  display: none !important;
}
</style>

.hv-modal-footer,
.hv-back-close-flex-div {
  display: none !important;
}

.hv-modal {
  height: 82vh !important;
  overflow-y: auto !important; 
  background: transparent !important;
}
.hv-modal-content {
  border-radius: 0px !important;
  padding: 0 !important;
  top: 0 !important;
    transform: translate(-50%, 0%) !important;
}


@media only screen and (max-height: 600px) {
  .hv-modal-content {
  top: 50% !important;
    transform: translate(-50%, -50%%) !important;
  }
  .hv-modal {
  height: 100% !important;
  overflow-y: auto !important; 
}
}  -->
