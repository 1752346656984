<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/segment" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
          Step 5 of 6
        </p>
        <p class="headline font-weight-black mb-2">Do declarations</p>
        <p class="subtitle-1 font-weight-regular txt-666">By pressing continue, you acknowledge and confirm that the information provided by you is true and correct.</p>

        <div v-if="!dataloader">
          <!-- <p class="font-weight-medium subtitle-2 lh-16 mb-0">Are you politically exposed or related person ?</p>
                    <v-chip-group column class="mb-4">
                        <v-chip @click="politically = tag" :color="politically == tag ? '#000' : '#666666'"
                            :text-color="politically == tag ? '#fff' : '#666666'" v-for="tag in politicallys"
                            :outlined="politically != tag" :key="tag">
                            {{ tag }}
                        </v-chip>
                    </v-chip-group> -->

          <p class="font-weight-medium subtitle-2 lh-16 mb-0">Select the following :</p>

          <v-checkbox :on-icon="'mdi-checkbox-marked-circle'" :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="taxes" color="#000" label="I pay my taxes in India"></v-checkbox>

          <v-checkbox :on-icon="'mdi-checkbox-marked-circle'" :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="terms" color="#000" label="I wish to avail of the facility of internet trading / wireless technology"></v-checkbox>

          <v-checkbox @click="onCheckboxClicked($event, 1)" :on-icon="'mdi-checkbox-marked-circle'" :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="standInstruct" color="#000" label="I accept all standing instructions"></v-checkbox>
          <v-row>
            <v-col cols="8"> <v-checkbox :on-icon="'mdi-checkbox-marked-circle'" :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="funds" color="#000" label="I want my funds to be settled after every"></v-checkbox></v-col>
            <v-col cols="4">
              <v-select v-model="fundis" :items="['90 days', '30 days']" hide-details append-icon="mdi-chevron-down"></v-select>
            </v-col>
          </v-row>

          <v-checkbox @click="onCheckboxClicked($event, 2)" :disabled="mtf" :on-icon="'mdi-checkbox-marked-circle'" :off-icon="'mdi-checkbox-blank-circle-outline'" hide-details v-model="ddpiform" color="#000">
            <template #label>
              <span>
                Enable DDPI to sell stocks instantly <span class="caption font-weight-medium txt-000">({{ mtf ? "mandatory for MTF enabled" : "optional" }})</span> <br />
                <span class="caption font-weight-medium">₹250 charges is applicable</span>
              </span>
            </template>
          </v-checkbox>

          <v-btn height="48px" :disabled="taxes != true || standInstruct != true || terms != true" @click="getDeclaration('set')" color="#000" class="white--text text-none rounded-pill elevation-0 mt-6 d-none d-md-flex"
            ><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn
          >

          <v-footer padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
            <v-btn height="48px" :disabled="taxes != true || standInstruct != true || terms != true" @click="getDeclaration('set')" color="#000" block class="white--text text-none rounded-pill elevation-0"><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
          </v-footer>
        </div>
        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/dec.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="standInstdialog" class="dec-pop" persistent transition="dialog-bottom-transition">
      <div class="overflow-hidden">
        <v-card width="100%" class="pop-temp mx-0 ma-lg-auto overflow-y-auto pb-4">
          <div class="pt-3 dec-pop-head" style="position: fixed; background-color: #fff; z-index: 1">
            <v-toolbar dense flat color="#fff" class="px-md-4">
              <p class="title mb-0 text-left font-weight-bold">Standing instructions</p>
              <v-spacer></v-spacer>
              <v-icon @click="(standInstdialog = false), (standInstruct = false)">mdi-close</v-icon>
            </v-toolbar>
            <v-divider></v-divider>
          </div>

          <div class="pa-4 px-md-7 px-lg-8 mt-16">
            <p class="font-weight-medium lh-16 mb-0">I would like to receive the Contract Notes</p>
            <v-chip-group column>
              <div :class="standinstlists.contract_note == tag.txt ? 'i-badge' : 'c-badge'" v-for="tag in standinstlists.contractnote" :key="tag.txt" class="mr-4 mt-2">
                <v-chip @click="standinstlists.contract_note = tag.txt" class="overflow-visible" :color="standinstlists.contract_note == tag.txt ? '#000' : '#666666'" :text-color="standinstlists.contract_note == tag.txt ? '#fff' : '#666666'" :outlined="standinstlists.contract_note != tag.txt">
                  <v-badge :color="standinstlists.contract_note == tag.txt ? '#000' : '#fff'" :bordered="standinstlists.contract_note != tag.txt" :content="tag.sub">
                    <span>
                      {{ tag.txt }}
                    </span>
                  </v-badge>
                </v-chip>
              </div>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">I / We instruct the DP to receive each and every credit in my / our account</p>
            <v-chip-group column>
              <v-chip @click="standinstlists.credit_in_my = tag" :color="standinstlists.credit_in_my == tag ? '#000' : '#666666'" :text-color="standinstlists.credit_in_my == tag ? '#fff' : '#666666'" v-for="tag in standinstlists.other" :outlined="standinstlists.credit_in_my != tag" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">I / We would like to instruct the DP to accept all the pledge instructions in my /our account without any other further instruction from my / our end</p>
            <v-chip-group column>
              <v-chip
                @click="standinstlists.pledge_instructions = tag"
                :color="standinstlists.pledge_instructions == tag ? '#000' : '#666666'"
                :text-color="standinstlists.pledge_instructions == tag ? '#fff' : '#666666'"
                v-for="tag in standinstlists.other"
                :outlined="standinstlists.pledge_instructions != tag"
                :key="tag"
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">Account Statement Requirement</p>
            <v-chip-group column>
              <v-chip @click="standinstlists.acc_statement = tag" :color="standinstlists.acc_statement == tag ? '#000' : '#666666'" :text-color="standinstlists.acc_statement == tag ? '#fff' : '#666666'" v-for="tag in standinstlists.accstat" :outlined="standinstlists.acc_statement != tag" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">I / We request you to send Electronic Transaction-cum-Holding Statement at the email ID as Stated in this KYC/KRA</p>
            <v-chip-group column>
              <v-chip
                @click="standinstlists.electronic_transaction = tag"
                :color="standinstlists.electronic_transaction == tag ? '#000' : '#666666'"
                :text-color="standinstlists.electronic_transaction == tag ? '#fff' : '#666666'"
                v-for="tag in standinstlists.other"
                :outlined="standinstlists.electronic_transaction != tag"
                :key="tag"
              >
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">I / We would like to receive the Annual Report</p>
            <v-chip-group column>
              <div :class="standinstlists.annual_report == tag.txt ? (l == 1 ? 'i-badge mr-16 pr-8' : 'i-badge mr-4') : l == 1 ? 'c-badge mr-16 pr-8' : 'c-badge mr-4'" v-for="(tag, l) in standinstlists.annulrep" :key="tag.txt" class="mt-2">
                <v-chip @click="standinstlists.annual_report = tag.txt" class="overflow-visible" :color="standinstlists.annual_report == tag.txt ? '#000' : '#666666'" :text-color="standinstlists.annual_report == tag.txt ? '#fff' : '#666666'" :outlined="standinstlists.annual_report != tag.txt">
                  <v-badge :color="standinstlists.annual_report == tag.txt ? '#000' : '#fff'" :bordered="standinstlists.annual_report != tag.txt" :content="tag.sub">
                    <span>
                      {{ tag.txt }}
                    </span>
                  </v-badge>
                </v-chip>
              </div>
            </v-chip-group>
            <!-- <v-chip-group column>
                            <v-chip @click="standinstlists.annual_report = tag"
                                :color="standinstlists.annual_report == tag ? '#000' : '#666666'"
                                :text-color="standinstlists.annual_report == tag ? '#fff' : '#666666'"
                                v-for="tag in standinstlists.annulrep" :outlined="standinstlists.annual_report != tag"
                                :key="tag">
                                {{ tag }}
                            </v-chip>
                        </v-chip-group> -->

            <p class="font-weight-medium lh-16 mb-0 mt-4">I / We wish to receive dividend / interest directly in to my bank account through ECS</p>
            <v-chip-group column>
              <v-chip @click="standinstlists.ECS = tag" :color="standinstlists.ECS == tag ? '#000' : '#666666'" :text-color="standinstlists.ECS == tag ? '#fff' : '#666666'" v-for="tag in standinstlists.other" :outlined="standinstlists.ECS != tag" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">I / We would like to share the email ID with the RTA</p>
            <v-chip-group column>
              <v-chip @click="standinstlists.RTA = tag" :color="standinstlists.RTA == tag ? '#000' : '#666666'" :text-color="standinstlists.RTA == tag ? '#fff' : '#666666'" v-for="tag in standinstlists.other" :outlined="standinstlists.RTA != tag" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">I wish to avail the TRUST facility using the Mobile number registered for SMS Alert Facility. I have read and understood the Terms and Conditions prescribed by CDSL for the same.</p>
            <v-chip-group column>
              <v-chip @click="standinstlists.CDSL_terms = tag" :color="standinstlists.CDSL_terms == tag ? '#000' : '#666666'" :text-color="standinstlists.CDSL_terms == tag ? '#fff' : '#666666'" v-for="tag in standinstlists.other" :outlined="standinstlists.CDSL_terms != tag" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">I/we require a Delivery Instruction Slip (DIS)</p>
            <v-chip-group column>
              <v-chip @click="standinstlists.DIS = tag" :color="standinstlists.DIS == tag ? '#000' : '#666666'" :text-color="standinstlists.DIS == tag ? '#fff' : '#666666'" v-for="tag in standinstlists.other" :outlined="standinstlists.DIS != tag" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>

            <p class="font-weight-medium lh-16 mb-0 mt-4">Would you like to open a Basic Services Demat Account (BSDA)</p>
            <v-chip-group column>
              <v-chip :disabled="tag != 'Yes'" @click="standinstlists.bsda = tag" :color="standinstlists.bsda == tag ? '#000' : '#666666'" :text-color="standinstlists.bsda == tag ? '#fff' : '#666666'" v-for="tag in standinstlists.bsdas" :outlined="standinstlists.bsda != tag" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </div>
          <v-footer color="transparent">
            <v-row no-glutters>
              <v-col cols="6" md="3" class="ml-md-auto">
                <v-btn style="border: 2px solid #444 !important; background-color: #fff" outlined height="48px" @click="(standInstruct = false), (standInstdialog = false)" color="#000" block class="text-none rounded-pill elevation-0"
                  ><span class="subtitle-1 font-weight-medium px-6">back</span></v-btn
                >
              </v-col>
              <v-col cols="6" md="3">
                <v-btn height="48px" block color="#000" class="white--text text-none rounded-pill elevation-0" @click="standInstdialog = false"><span class="white--text subtitle-1 font-weight-medium px-6">I accept</span></v-btn>
              </v-col>
            </v-row>
          </v-footer>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-model="ddpiInstdialog" persistent width="360px">
      <v-card width="100%" class="rounded-xl overflow-y-auto pb-2">
        <div class="px-5 pt-4">
          <p class="subtitle-1 mb-3 text-left font-weight-bold">DDPI/POA! instructions</p>
          <p class="mb-0 font-weight-regular">Great choice in opting for DDPI/POA! Please note that a separate link will be sent to your registered email address for completing the DDPI/POA process.</p>
        </div>
        <v-toolbar dense flat color="#fff" class="px-0">
          <v-spacer></v-spacer>
          <v-btn class="elevation-0 rounded-pill text-none white--text" color="black" @click="ddpiInstdialog = false">Ok</v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";
export default {
  data: () => ({
    taxes: false,
    terms: false,
    funds: false,
    ddpiInstdialog: false,
    standInstruct: false,
    fundis: "90 days",
    declarations: {},
    cardloader: false,
    dataloader: null,
    ddpiform: false,

    standInstdialog: false,
    standinstlists: {
      accstat: ["As per SEBI Regulation", "Daily", "Weekly", "Fortnightly", "Monthly"],
      annulrep: [
        {txt: "Electronic", sub: "Free"},
        {txt: "Physical", sub: "Applicable charges"},
        {txt: "Both Physical and Electronic", sub: "Applicable charges"},
      ],
      // annulrep: [
      //     "Electronic",
      //     "Physical",
      //     "Both Physical and Electronic"
      // ],
      contractnote: [
        {txt: "Electronic", sub: "Free"},
        {txt: "Physical", sub: "Rs 200 + GST (per Instance)"},
      ],
      other: ["Yes", "No"],
      bsdas: ["Opt out", "Yes", "No"],

      credit_in_my: "Yes",
      pledge_instructions: "Yes",
      acc_statement: "As per SEBI Regulation",
      electronic_transaction: "Yes",
      annual_report: "Electronic",
      ECS: "Yes",
      RTA: "Yes",
      CDSL_terms: "Yes",
      contract_note: "Electronic",
      DIS: "No",
      bsda: "Yes",
    },
    mtf: false,
    // politically: "",
    // politicallys: [
    //     "I am",
    //     "Related to",
    //     "I am not",
    // ],
  }),
  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.app_status != "rejected" && (p.stage == "declartaion" || p.stage == "signature" || p.stage == "nominee" || p.stage == "selfie" || p.stage == "submit")) {
          axiosThis.$emit("clicked", 5, response.data);
          axiosThis.mtf = response.data.data_type.MTf == "Yes" ? true : false;
          if (axiosThis.mtf) {
            axiosThis.ddpiInstdialog = true;
          }
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
  },
  mounted() {
    this.dataloader = true;
    this.getDeclaration();
  },
  methods: {
    getDeclaration(value) {
      let data;
      if (value == "set") {
        data = {
          mobile: atob(localStorage.getItem("hash")),
          declaration: {
            pay_tax: this.taxes,
            politically: "",
            standing_instructions: this.standinstlists,
            terms: this.terms,
            unused_fund: this.funds,
            days: this.fundis,
            DDPI: this.ddpiform,
          },
        };
      } else {
        data = JSON.stringify({mobile: atob(localStorage.getItem("hash"))});
      }

      let config = {
        method: "post",
        url: `${api.api_url}/getSignUpDeclaration`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          let dec = response.data.declaration;
          if (value != "set") {
            if (dec) {
              axiosThis.taxes = dec.pay_tax;
              axiosThis.standInstruct = dec.standing_instructions ? true : false;
              axiosThis.standinstlists = dec.standing_instructions ? dec.standing_instructions : axiosThis.standinstlists;
              axiosThis.terms = dec.terms;
              axiosThis.funds = dec.unused_fund;
              axiosThis.fundis = dec.days ? dec.days : "90 days";
              axiosThis.ddpiform = axiosThis.mtf ? true : dec.DDPI;
            }
            axiosThis.dataloader = false;
          } else {
            if (dec.terms) {
              axiosThis.$emit("clicked", "signature");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    onCheckboxClicked(e, n) {
      if (this.standInstruct && n == 1) {
        this.standInstdialog = true;
      }
      if (this.ddpiform && n == 2) {
        this.ddpiInstdialog = true;
      }
    },
  },
};
</script>
