<template>
  <div>
    <v-row no-glutters class="body-rows">
      <v-col cols="12" md="7" class="left-cols1 pr-md-8">
        <p class="subtitle-2 font-weight-bold mb-2 mb-md-5 txt-666">
          <v-btn :disabled="cardloader" to="/nominee" icon><v-icon>mdi-arrow-left</v-icon></v-btn> Step 5 of 6
        </p>
        <p class="headline font-weight-black mb-2">Let's take a selfie</p>
        <p class="subtitle-1 font-weight-regular txt-666">Click a selfie for your KYC verification. Please make sure you are in a well-lit area.</p>

        <v-alert v-if="rejectdata.app_status == 'rejected' ? rejectdata.stage_wise_approval.selfie == 'no' : ''" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular"
            >Your Selfie have been rejected due <b>{{ rejectdata.image_reject_reason }}</b></span
          >
        </v-alert>

        <v-alert v-if="georang" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
          <span class="subtitle-2 font-weight-regular">Your current location is outside India. Please ensure you are within India to proceed with the eKYC process.</span>
        </v-alert>

        <div v-if="!dataloader">
          <div v-if="!photoreject">
            <v-alert v-if="alerts" border="left" colored-border type="info" style="border: 1px solid #ebeef0 !important" class="mt-4 mx-auto rounded-xl text-left py-2" color="warning" elevation="0">
              <span class="caption">
                While taking a selfie, you should allow permission to access your <b>camera</b> and <b>location</b>. If your system doesn't support access, kindly change the device and continue the process, or if access is denied, you will not be able to proceed to the next step.
              </span>
            </v-alert>
            <v-card class="rounded-xl elevation-0">
              <v-card v-show="isCameraOpen && isLoading" width="100%" class="text-center py-16 my-16 elevation-0 rounded-xl">
                <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
              </v-card>
              <v-file-input class="elevation-0 rounded-pill d-none" small-chips truncate-length="14" prepend-icon="" v-model="photoFile" accept="image/png, image/jpg, image/jpeg"></v-file-input>
              <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{flash: isShotPhoto}">
                <div style="overflow: hidden; position: relative">
                  <v-card width="100%" class="elevation-0" style="transform: scaleX(-1) !important">
                    <video class="" v-show="!isPhotoTaken" style="position: relative !important; top: 0 !important; left: 50% !important; transform: translate(-50%, 0px) !important; object-fit: cover" ref="camera" :width="285" :height="265" autoplay></video>
                  </v-card>

                  <div style="position: absolute; width: 100%; bottom: 16px !important" v-if="isCameraOpen && !isLoading && !isPhotoTaken" class="text-center">
                    <v-btn @click="takePhoto()" height="40px" color="#000" fab class="white--text text-none rounded-pill elevation-0 mt-3 px-4"
                      ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.87215 0.666995C6.79868 0.666977 6.73477 0.666961 6.67378 0.670655C5.90255 0.717363 5.22721 1.20412 4.93902 1.921C4.91623 1.97769 4.89603 2.03833 4.87281 2.10804L4.86788 2.12285C4.83695 2.21563 4.83024 2.23482 4.82454 2.249C4.72848 2.48796 4.50337 2.65021 4.24629 2.66578C4.23122 2.66669 4.2086 2.667 4.10406 2.667L4.06682 2.66699C3.85692 2.66695 3.70269 2.66692 3.57074 2.67999C2.30591 2.80531 1.30531 3.8059 1.17999 5.07074C1.16693 5.20265 1.16696 5.35068 1.167 5.55056L1.167 10.8279C1.16699 11.3645 1.16699 11.8074 1.19646 12.1682C1.22708 12.5429 1.29279 12.8874 1.45765 13.211C1.71331 13.7127 2.12126 14.1207 2.62303 14.3763C2.94658 14.5412 3.29105 14.6069 3.66578 14.6375C4.02658 14.667 4.46948 14.667 5.00612 14.667H11.9946C12.5312 14.667 12.9741 14.667 13.3349 14.6375C13.7096 14.6069 14.0541 14.5412 14.3776 14.3763C14.8794 14.1207 15.2874 13.7127 15.543 13.211C15.7079 12.8874 15.7736 12.5429 15.8042 12.1682C15.8337 11.8074 15.8337 11.3645 15.8337 10.8279L15.8337 5.55056C15.8337 5.35069 15.8337 5.20264 15.8207 5.07074C15.6954 3.8059 14.6948 2.80531 13.4299 2.67999C13.298 2.66692 13.1438 2.66695 12.9338 2.66699L12.8966 2.667C12.7921 2.667 12.7695 2.66669 12.7544 2.66578C12.4973 2.65021 12.2722 2.48796 12.1761 2.249C12.1704 2.23482 12.1637 2.21563 12.1328 2.12285L12.1279 2.10803C12.1046 2.03835 12.0844 1.97768 12.0617 1.921C11.7735 1.20412 11.0981 0.717363 10.3269 0.670655C10.2659 0.666961 10.202 0.666977 10.1285 0.666995H6.87215ZM11.167 8.33366C11.167 9.80642 9.97309 11.0003 8.50033 11.0003C7.02757 11.0003 5.83366 9.80642 5.83366 8.33366C5.83366 6.8609 7.02757 5.66699 8.50033 5.66699C9.97309 5.66699 11.167 6.8609 11.167 8.33366Z"
                          fill="white"
                        /></svg
                    ></v-btn>
                  </div>
                </div>
                <!-- <img class="rounded-lg" width="285px" height="265px" style="transform: scaleX(-1) !important;"
                                :src="camimage" :alt="camimage"> -->
                <div v-show="isPhotoTaken" class="text-center">
                  <v-card width="100%" class="elevation-0" style="transform: scaleX(-1) !important">
                    <img style="object-fit: cover" class="rounded-lg" width="286px" height="265px" :src="camimage" :alt="camimage" />
                    <canvas class="rounded-lg d-none" style="object-fit: cover" id="photoTaken" ref="canvas" width="285px" :height="265"></canvas>
                  </v-card>

                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M6.99967 13.7083C10.0603 13.7083 12.5413 12.7942 12.5413 11.6667C12.5413 10.5391 10.0603 9.625 6.99967 9.625C3.9391 9.625 1.45801 10.5391 1.45801 11.6667C1.45801 12.7942 3.9391 13.7083 6.99967 13.7083Z" fill="#9BCED3" />
                      <path d="M7 0.291016C4.58376 0.291016 2.625 2.24976 2.625 4.66602C2.625 7.27618 5.9378 10.6442 6.79794 11.4747C6.91127 11.5841 7.08873 11.5841 7.20206 11.4747C8.0622 10.6442 11.375 7.27618 11.375 4.66602C11.375 2.24976 9.41624 0.291016 7 0.291016Z" fill="#E86C60" />
                      <path d="M6.99935 6.1237C7.80476 6.1237 8.45768 5.47078 8.45768 4.66536C8.45768 3.85995 7.80476 3.20703 6.99935 3.20703C6.19393 3.20703 5.54102 3.85995 5.54102 4.66536C5.54102 5.47078 6.19393 6.1237 6.99935 6.1237Z" fill="white" />
                    </svg>
                    <span class="ml-2 font-weight-medium subtitle-2"
                      ><a target="_blank" style="color: #666; text-decoration: none !important" :href="geolocation">{{ uaddress ? uaddress : geolocation.split("q=")[1] }}</a></span
                    >
                  </p>
                  <p class="font-weight-bold fs-18" style="color: #0037b7 !important">Check picture quality</p>
                  <p class="txt-666 fs-16 font-weight-regular mb-16 mb-md-3" style="line-height: 18px">Your entire photo should be clear, all information readable, and without any glare or blur.</p>
                </div>
              </div>
              <v-row v-if="isPhotoTaken && isCameraOpen && !georang" class="d-none d-md-flex">
                <v-col cols="6" class="text-right pl-6">
                  <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="camClear()" color="#000" class="text-none rounded-pill elevation-0 mt-6" block><span class="subtitle-1 font-weight-medium px-6">Try again</span></v-btn>
                </v-col>
                <v-col cols="6" class="text-left pr-6">
                  <v-btn height="48px" @click="downloadImage()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-6" block><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn>
                </v-col>
              </v-row>

              <v-btn v-if="georang" style="border: 2px solid #444 !important" outlined height="48px" @click="camClear()" color="#000" class="text-none rounded-pill elevation-0 mt-6" block><span class="subtitle-1 font-weight-medium px-6">Try again</span></v-btn>
            </v-card>
            <v-footer v-if="isPhotoTaken && isCameraOpen" padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
              <v-btn v-if="georang" style="border: 2px solid #444 !important" outlined height="48px" @click="camClear()" color="#000" class="text-none rounded-pill elevation-0" block><span class="subtitle-1 font-weight-medium px-6">Try again</span></v-btn>

              <v-row v-else>
                <v-col cols="6">
                  <v-btn style="border: 2px solid #444 !important" outlined height="48px" @click="camClear()" color="#000" class="text-none rounded-pill elevation-0" block><span class="subtitle-1 font-weight-medium px-6">Try again</span></v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn height="48px" @click="downloadImage()" color="#000" class="white--text text-none rounded-pill elevation-0" block><span class="white--text subtitle-1 font-weight-medium">Continue</span></v-btn>
                </v-col>
              </v-row>
            </v-footer>
            <div v-if="!photoFile && !isCameraOpen">
              <v-card class="text-center rounded-lg elevation-0 pt-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                  <path d="M34.75 4.6875H20C17.7909 4.6875 16 6.47836 16 8.6875V23.4375" stroke="#C2C2C2" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" />
                  <path d="M133.188 23.4375V8.6875C133.188 6.47836 131.397 4.6875 129.187 4.6875H114.438" stroke="#C2C2C2" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" />
                  <path d="M114.438 93.75H129.188C131.397 93.75 133.188 91.9591 133.188 89.75V75" stroke="#C2C2C2" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" />
                  <path d="M16 75V89.75C16 91.9591 17.7909 93.75 20 93.75H34.75" stroke="#C2C2C2" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" />
                  <path d="M58.2188 88.167V105.469L22.5984 115.645C14.55 117.944 9 125.304 9 133.673V145.312H140.25V133.673C140.25 125.301 134.7 117.944 126.652 115.645L91.0312 105.469V88.167" fill="#F2F2F2" />
                  <path d="M74.625 93.75C56.5031 93.75 41.8125 74.3719 41.8125 56.25V46.875C41.8125 28.7531 56.5031 14.0625 74.625 14.0625C92.7469 14.0625 107.438 28.7531 107.438 46.875V56.25C107.438 74.3719 92.7469 93.75 74.625 93.75Z" fill="#F2F2F2" /></svg
                ><br />

                <v-btn @click="openCam()" height="40px" color="#000" class="white--text text-none rounded-pill elevation-0 mt-3 px-4"
                  ><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.87215 0.666995C6.79868 0.666977 6.73477 0.666961 6.67378 0.670655C5.90255 0.717363 5.22721 1.20412 4.93902 1.921C4.91623 1.97769 4.89603 2.03833 4.87281 2.10804L4.86788 2.12285C4.83695 2.21563 4.83024 2.23482 4.82454 2.249C4.72848 2.48796 4.50337 2.65021 4.24629 2.66578C4.23122 2.66669 4.2086 2.667 4.10406 2.667L4.06682 2.66699C3.85692 2.66695 3.70269 2.66692 3.57074 2.67999C2.30591 2.80531 1.30531 3.8059 1.17999 5.07074C1.16693 5.20265 1.16696 5.35068 1.167 5.55056L1.167 10.8279C1.16699 11.3645 1.16699 11.8074 1.19646 12.1682C1.22708 12.5429 1.29279 12.8874 1.45765 13.211C1.71331 13.7127 2.12126 14.1207 2.62303 14.3763C2.94658 14.5412 3.29105 14.6069 3.66578 14.6375C4.02658 14.667 4.46948 14.667 5.00612 14.667H11.9946C12.5312 14.667 12.9741 14.667 13.3349 14.6375C13.7096 14.6069 14.0541 14.5412 14.3776 14.3763C14.8794 14.1207 15.2874 13.7127 15.543 13.211C15.7079 12.8874 15.7736 12.5429 15.8042 12.1682C15.8337 11.8074 15.8337 11.3645 15.8337 10.8279L15.8337 5.55056C15.8337 5.35069 15.8337 5.20264 15.8207 5.07074C15.6954 3.8059 14.6948 2.80531 13.4299 2.67999C13.298 2.66692 13.1438 2.66695 12.9338 2.66699L12.8966 2.667C12.7921 2.667 12.7695 2.66669 12.7544 2.66578C12.4973 2.65021 12.2722 2.48796 12.1761 2.249C12.1704 2.23482 12.1637 2.21563 12.1328 2.12285L12.1279 2.10803C12.1046 2.03835 12.0844 1.97768 12.0617 1.921C11.7735 1.20412 11.0981 0.717363 10.3269 0.670655C10.2659 0.666961 10.202 0.666977 10.1285 0.666995H6.87215ZM11.167 8.33366C11.167 9.80642 9.97309 11.0003 8.50033 11.0003C7.02757 11.0003 5.83366 9.80642 5.83366 8.33366C5.83366 6.8609 7.02757 5.66699 8.50033 5.66699C9.97309 5.66699 11.167 6.8609 11.167 8.33366Z"
                      fill="white"
                    /></svg
                  ><span class="white--text subtitle-1 font-weight-medium ml-2">Open Camera</span></v-btn
                >
              </v-card>
            </div>
          </div>
          <div v-if="camimagebe" class="text-center">
            <!-- <p><span class="txt-666">File names</span> : <b>{{ camimagedata.name }}</b>
                        </p> -->
            <div class="text-center mb-2">
              <v-card width="100%" class="elevation-0" style="transform: scaleX(-1) !important">
                <img style="object-fit: cover" class="rounded-lg" width="286px" height="265px" :src="camimage" :alt="camimage" />
                <canvas class="rounded-lg d-none" style="object-fit: cover" id="photoTaken" ref="canvas" width="285px" :height="265"></canvas>
              </v-card>
            </div>

            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M6.99967 13.7083C10.0603 13.7083 12.5413 12.7942 12.5413 11.6667C12.5413 10.5391 10.0603 9.625 6.99967 9.625C3.9391 9.625 1.45801 10.5391 1.45801 11.6667C1.45801 12.7942 3.9391 13.7083 6.99967 13.7083Z" fill="#9BCED3" />
                <path d="M7 0.291016C4.58376 0.291016 2.625 2.24976 2.625 4.66602C2.625 7.27618 5.9378 10.6442 6.79794 11.4747C6.91127 11.5841 7.08873 11.5841 7.20206 11.4747C8.0622 10.6442 11.375 7.27618 11.375 4.66602C11.375 2.24976 9.41624 0.291016 7 0.291016Z" fill="#E86C60" />
                <path d="M6.99935 6.1237C7.80476 6.1237 8.45768 5.47078 8.45768 4.66536C8.45768 3.85995 7.80476 3.20703 6.99935 3.20703C6.19393 3.20703 5.54102 3.85995 5.54102 4.66536C5.54102 5.47078 6.19393 6.1237 6.99935 6.1237Z" fill="white" />
              </svg>
              <span class="ml-2 font-weight-medium subtitle-2"
                ><a target="_blank" style="color: #666; text-decoration: none !important" :href="geolocation">{{ uaddress ? uaddress : geolocation.split("q=")[1] }}</a></span
              >
            </p>

            <p class="font-weight-bold fs-18" style="color: #0037b7 !important">Check picture quality</p>
            <p class="txt-666 fs-16 font-weight-regular mb-16 mb-md-3" style="line-height: 18px">Your entire photo should be clear, all information readable, and without any glare or blur.</p>
            <!-- <p> IPaddress :
                            <span class="ml-2 font-weight-bold" style="color:#0037B7;text-decoration:none !important;">{{
                                ipaddress }}</span>
                        </p> -->
            <v-btn
              v-if="georang"
              style="border: 2px solid #444 !important"
              outlined
              height="48px"
              @click="(camimagebe = false), (camimage = null), (camimagedata = []), (photoFile = null), (photoreject = false), (ipaddress = null), (geolocation = null), $emit('changed', 5), (georang = false)"
              color="#000"
              class="text-none rounded-pill elevation-0 mt-6"
              block
              ><span class="subtitle-1 font-weight-medium px-6">Try again</span></v-btn
            >

            <v-row v-else class="d-none d-md-flex">
              <v-col cols="6" class="text-right pl-6">
                <v-btn
                  style="border: 2px solid #444 !important"
                  outlined
                  height="48px"
                  @click="(camimagebe = false), (camimage = null), (camimagedata = []), (photoFile = null), (photoreject = false), (ipaddress = null), (geolocation = null), $emit('changed', 5), (georang = false)"
                  color="#000"
                  class="text-none rounded-pill elevation-0 mt-6"
                  block
                  ><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn
                >
              </v-col>
              <v-col cols="6" class="text-left pr-6">
                <v-btn height="48px" @click="toNext()" color="#000" class="white--text text-none rounded-pill elevation-0 mt-6" block><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
              </v-col>
            </v-row>

            <v-footer padless style="position: absolute; bottom: 40px; left: 0; width: 100%" color="#fff" class="px-3 pt-3 pb-1 d-md-none">
              <v-btn
                v-if="georang"
                style="border: 2px solid #444 !important"
                outlined
                height="48px"
                @click="(camimagebe = false), (camimage = null), (camimagedata = []), (photoFile = null), (photoreject = false), (ipaddress = null), (geolocation = null), $emit('changed', 5), (georang = false)"
                color="#000"
                class="text-none rounded-pill elevation-0"
                block
                ><span class="subtitle-1 font-weight-medium px-6">Try again</span></v-btn
              >

              <v-row v-else>
                <v-col cols="6">
                  <v-btn
                    style="border: 2px solid #444 !important"
                    outlined
                    height="48px"
                    @click="(camimagebe = false), (camimage = null), (camimagedata = []), (photoFile = null), (photoreject = false), (ipaddress = null), (geolocation = null), $emit('changed', 5), (georang = false)"
                    color="#000"
                    class="text-none rounded-pill elevation-0"
                    block
                    ><span class="subtitle-1 font-weight-medium px-6">Clear</span></v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn height="48px" @click="toNext()" color="#000" class="white--text text-none rounded-pill elevation-0" block><span class="white--text subtitle-1 font-weight-medium px-6">Continue</span></v-btn>
                </v-col>
              </v-row>
            </v-footer>
          </div>
        </div>
        <div v-else>
          <v-card width="100%" class="text-center py-16 elevation-0 mt-10">
            <v-progress-circular class="mx-auto" :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
          </v-card>
        </div>
      </v-col>
      <v-col cols="5" class="rgt-cols d-none d-md-block pt-0">
        <v-container fill-height class="d-block text-center">
          <img src="@/assets/selfie.svg" class="rgt-img" alt="rgt-img" />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import api from "@/api";

export default {
  data: () => ({
    other: true,
    camimagebe: false,
    ///////////////
    photoreject: false,
    photoFile: null,
    cardloader: false,
    //
    isCameraOpen: false,
    isPhotoTaken: false,
    camimage: null,
    camimagedata: [],
    isShotPhoto: false,
    isLoading: false,
    link: "#",
    signtbtn: 0,

    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
    },
    disabled: false,
    geolocation: null,
    ipaddress: null,
    proofupdate: [],
    dataloader: null,
    alerts: false,
    uaddress: "",
    IncomeProofselect: null,
    rejectdata: [],

    georang: null,
  }),
  created() {
    let config = {
      method: "post",
      url: api.api_url + "/getstart?mob=" + atob(localStorage.getItem("hash")),
      headers: {
        "Content-Type": "application/json",
      },
    };

    let axiosThis = this;
    axios
      .request(config)
      .then((response) => {
        let p = response.data.data_type;
        if (response.data.data_type && response.data.stage_wise_approval.selfie != "yes" && (p.stage == "selfie" || p.stage == "submit")) {
          axiosThis.$emit("clicked", 5, response.data);
          response.data.app_status == "rejected" && response.data.stage_wise_approval.selfie == "no" ? (axiosThis.rejectdata = response.data) : (axiosThis.rejectdata = []);
          axiosThis.proofupdate = p;
          if (this.$refs.camera != undefined) {
            axiosThis.stopCameraStream();
          }
        } else {
          axiosThis.$router.push(p ? {name: p.stage} : "/");
        }
      })
      .catch((error) => {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
        axiosThis.cardloader = false;
        // alert("Server is not reachable, please try again later. \n  For support Contact us : 93 8010 8010  or  \n Mail us : assist@zebuetrade.com")
      });
  },

  methods: {
    toNext() {
      this.$emit("changed", 0);
      if (this.rejectdata.app_status == "rejected" && this.rejectdata.stage_wise_approval.selfie == "no") {
        this.$emit("steged", "selfie");
      } else {
        this.$emit("clicked", "submit");
      }
    },
    openCam() {
      this.alerts = true;
      if (this.geolocation) {
        this.isCameraOpen = true;
        this.createCameraElement();
      } else {
        this.getLocation();
      }
    },
    closeCam() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    },

    camClear() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
      setTimeout(() => {
        this.openCam();
      }, 100);
      this.georang = false;
    },

    createCameraElement() {
      this.isLoading = true;

      var self = this;
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          self.alerts = false;
          self.isLoading = false;
          self.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          self.isLoading = false;
          alert("Check the camera access Settings > Privacy > Camera and location. In Allow access to the camera and location on this device or This browser doesn't support it please try in another device. if the camera doesn't open.", error);
          this.isCameraOpen = false;
          self.stopCameraStream();
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, -35, 0, 345, 265);
      const canvas = document.getElementById("photoTaken").toDataURL("image/png");
      this.stopCameraStream();
      this.camimage = canvas;

      let rang = this.geolocation ? this.geolocation.split("q=")[1].split(",") : null;
      this.georang = rang ? !(Number(rang[0]) > 8 && Number(rang[0]) < 37 && Number(rang[1]) > 68 && Number(rang[1]) < 98) : false;
    },
    downloadImage() {
      const canvas = document.getElementById("photoTaken").toDataURL("image/png");
      let file = this.dataURLtoFile(canvas, "mySnap.png");
      this.photoFile = file;
      this.camimagedata["name"] = this.photoFile.name;
      this.camimagedata["size"] = (this.photoFile.size / 1048576).toFixed(1);
      this.closeCam();
      this.fileUpload();
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    getFileis(name, link) {
      let config = {
        method: "post",
        url: api.api_url + `/filsend?path=${link}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      var axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            let hash = atob(localStorage.getItem("hash"));
            if (name == "PIC") {
              let url = `${api.api_img}/${link}`;
              let blob = new Blob([url]);
              let file = new File([blob], `${url.split(`${hash}/`)[1].split(".")[0]}`, {type: `image/${url.split(`${hash}/`)[1].split(".")[1]}`});
              axiosThis.camimage = url;
              axiosThis.photoFile = file;
              axiosThis.camimagedata["name"] = axiosThis.photoFile.name;
              axiosThis.camimagedata["size"] = (axiosThis.photoFile.size / 1048576).toFixed(1);
              axiosThis.camimagedata["from"] = "be";
            }
            axiosThis.dataloader = false;
            axiosThis.camimagebe = true;
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.$emit("snack", 0, error);
        });
    },
    fileUpload() {
      if (this.photoreject == false && this.photoFile && (this.photoFile["type"] == "image/jpeg" || this.photoFile["type"] == "image/jpg" || this.photoFile["type"] == "image/png")) {
        this.dataloader = true;
        this.cardloader = true;
        let formData = new FormData();

        formData.append("clientimage", this.photoFile);
        formData.append("IPVlocation", this.geolocation ? this.geolocation : "");
        formData.append("IPaddress", this.ipaddress ? this.ipaddress : "");

        var axiosThis = this;

        axios
          .post(api.api_url + `/uploadSignupUserFiles/` + atob(localStorage.getItem("hash")), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // data: data
          })
          .then(function (response) {
            if (response.data["msg"] == "All Files Upload sucessfully") {
              axiosThis.toNext();
            }
            axiosThis.dataloader = false;
            axiosThis.cardloader = false;
          })
          .catch(function () {
            axiosThis.cardloader = false;
            axiosThis.dataloader = false;
          });
      } else {
        this.cardloader = false;
        this.$emit("snack", 2, "Only png, jpg, jpeg image formats are supported.");
      }
    },
    async getPublicIP() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        this.ipaddress = data.ip;
      } catch (error) {
        console.error(error);
        this.ipaddress = null;
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        setTimeout(() => {
          this.getLocation();
        }, 1000);
      }
      this.getPublicIP();
    },
    showPosition(position) {
      this.geolocation = `https://www.google.com/search?q=${position.coords.latitude},${position.coords.longitude}`;
    },
  },

  mounted() {
    this.dataloader = true;
    var config = {
      method: "post",
      url: api.api_url + "/check_file?mob=" + atob(localStorage.getItem("hash")),
      headers: {},
    };
    var axiosThis = this;

    axios(config)
      .then(function (response) {
        if (response.data != "no file data found") {
          axiosThis.photoreject = response.data["Photo File"] == true ? true : false;
          axiosThis.IncomeProofselect = response.data.incometype == "Bank statement" ? true : false;
          if (axiosThis.rejectdata.app_status == "rejected" && axiosThis.rejectdata.stage_wise_approval.selfie == "no") {
            axiosThis.photoreject = false;
            axiosThis.geolocation = "";
            axiosThis.ipaddress = "";
            axiosThis.dataloader = false;
          } else if (response.data["Photo File"] == true) {
            axiosThis.geolocation = response.data.IPVlocation;
            let rang = axiosThis.geolocation ? axiosThis.geolocation.split("q=")[1].split(",") : null;
            axiosThis.georang = rang ? !(Number(rang[0]) > 8 && Number(rang[0]) < 37 && Number(rang[1]) > 68 && Number(rang[1]) < 98) : false;
            axiosThis.ipaddress = response.data.IPaddress;
            let name = "PIC";
            let link = response.data["Photo"];
            axiosThis.getFileis(name, link);
          } else {
            axiosThis.dataloader = false;
            axiosThis.getLocation();
          }
          if ("pan_Status" in response.data && response.data["pan_Status"]) {
            var panresponse = JSON.parse(response.data["pan_Status"]);
            if ("pan" in panresponse && panresponse["pan"] == true) {
              axiosThis.proofupdate["panproof"] = true;
            } else {
              axiosThis.proofupdate["panproof"] = false;
            }
          }
        } else {
          axiosThis.dataloader = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        axiosThis.$emit("snack", 0, error);
      });
    this.getPublicIP();
  },
};
</script>
